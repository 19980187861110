import React from 'react'

import { videoTours } from '../../../data/video-tours'
import CardTours from './card-tours'

function CardsTours() {
  return (
    <ul className="grid lg:grid-cols-3 gap-6 max-w-lg mx-auto lg:max-w-full">
      {videoTours &&
        videoTours.map((card, index) => (
          <li key={card.id} className="h-full">
            <CardTours
              {...card}
              count={index + 1}
              className="bg-white h-full"
            />
          </li>
        ))}
    </ul>
  )
}

export default CardsTours
