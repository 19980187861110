import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'
import CardsTours from '../components/cards/tours/cards-tours'
import CreateShowCta from '../components/ctas/create-show'

function VideoToursPage(props) {
  const { data } = props

  const description = `Automatic video tours. We do all the work.`

  return (
    <Layout flush={true}>
      <SEO title="Video Tours" description={description} slug="/video-tours" />
      <StructuredData
        pageType="webPage"
        slug="video-tours"
        pageTitle="Video Tours"
        description={description}
      />
      <div className="bg-purple-200 pb-10 lg:pb-0 lg:mt-24 mb-16 lg:mb-32">
        <div className="container flex flex-col lg:flex-row relative">
          <div
            className="-mx-4 lg:mx-0 sm:order-2 lg:absolute right-0 z-20 lg:w-1/2 transform lg:-translate-y-1/2"
            style={{
              top: `50%`,
            }}
          >
            <Img className="shadow" fluid={data.house.childImageSharp.fluid} />
          </div>
          <div className="text-center lg:text-left py-10 lg:py-20 lg:w-1/2 lg:pr-24">
            <h1 className="text-3xl lg:text-4xl leading-tight mb-6 lg:mb-10 text-purple-900">
              What happens when you can&apos;t physically show homes?
            </h1>
            <p className="md:text-lg">
              We created Real Estate Shows for this very reason. To deliver the
              best virtual tour software real estate agents have ever
              experienced.
            </p>
          </div>
        </div>
      </div>

      <div className="container max-w-3xl text-center">
        <div className="w-32 h-1 bg-purple-200 mx-auto mb-6 lg:mb-10"></div>
        <p className="lg:text-xl">
          You got into real estate to connect with people. To help people. Not
          to become a technology expert that needs to learn several software
          solutions just to create a single virtual tour that looks good, right?
        </p>
        <div className="w-32 h-1 bg-purple-200 mx-auto mt-6 lg:mt-10"></div>
      </div>

      <div className="container py-10 lg:py-20">
        <h2 className="max-w-5xl mx-auto text-center text-2xl lg:text-3xl mb-12">
          Imagine a world where all you needed was a property address.
          Everything else was taken care of for you.
        </h2>
        <div className="text-center mb-10">
          <Img alt="gears" fixed={data.automatic.childImageSharp.fixed} />
        </div>
        <ul className="grid gap-10 grid-cols-2 md:grid-cols-5">
          {[
            { heading: `No`, copy: `Photo Uploading` },
            { heading: `No`, copy: `Video Software` },
            { heading: `No`, copy: `Coding` },
            { heading: `Automatic`, copy: `Video Creation` },
            { heading: `Automatic`, copy: `Property Pages` },
          ].map(feature => (
            <li
              className="lg:py-4 text-center md:border-r lg:border-r-2 border-purple-200 last:border-r-0 md:pr-10"
              key={feature.copy}
            >
              <div className="text-purple-600 font-semibold text-sm sm:text-base md:text-sm lg:text-lg">
                {feature.heading}
              </div>
              <div className="leading-tight text-sm sm:text-base md:text-sm lg:text-lg">
                {feature.copy}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-purple-200 py-10 lg:py-20">
        <div className="container text-center">
          <h2 className="text-purple-900 text-2xl lg:text-3xl mb-12">
            You won&apos;t believe how easy Real Estate Shows is.
          </h2>
          <CardsTours />
        </div>
      </div>

      <div className="py-10 px-4 lg:py-20">
        <CreateShowCta
          header="Ready to Get Started?"
          buttonText="Create Your First Show"
          buttonUrl="https://app.realestateshows.com/listings/preview"
        />
      </div>
    </Layout>
  )
}

VideoToursPage.propTypes = {
  data: PropTypes.object,
}

export default VideoToursPage

export const query = graphql`
  query {
    house: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    automatic: file(relativePath: { eq: "automatic.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
