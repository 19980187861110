import React from 'react'

import IconAddress from '../svgs/enter-address.inline.svg'
import IconWork from '../svgs/we-work.inline.svg'
import IconCustomers from '../svgs/delight-customers.inline.svg'

export const videoTours = [
  {
    id: `card_1`,
    title: `You Enter the Address`,
    content: `You give us the property address, which lets us gather all the information needed.`,
    icon: <IconAddress className="mx-auto" />,
  },
  {
    id: `card_2`,
    title: `We Do the Work`,
    content: `We pull images, define focus points and transitions, and create the show & property page.`,
    icon: <IconWork className="mx-auto" />,
  },
  {
    id: `card_3`,
    title: `You Delight Your Customers`,
    content: `Take the credit, we don't mind. Show off your Real Estate Show anywhere you want.`,
    icon: <IconCustomers className="mx-auto mt-12" />,
  },
]
