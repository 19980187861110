import React from 'react'
import PropTypes from 'prop-types'

function CardTours(props) {
  const { count, title, content, icon, className } = props

  const defaultClasses = `rounded-lg px-6 py-12 relative`
  const classes = className ? `${defaultClasses} ${className}` : defaultClasses

  return (
    <div className={classes}>
      <div className="absolute top-0 left-0 mt-6 ml-6 text-purple-700 text-5xl leading-none">
        {count}
      </div>
      <div className="mx-auto text-center mb-6">{icon}</div>
      <div className="font-semibold text-xl lg:text-2xl mb-3">{title}</div>
      <div className="h-1 w-10 bg-purple-200 rounded-full mb-3 mx-auto"></div>
      <p className="text-lg">{content}</p>
    </div>
  )
}

CardTours.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
}

export default CardTours
